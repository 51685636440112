import * as THREE from "three"
import React, { useEffect, useRef, useState } from "react"
import { useLoader, useFrame } from "react-three-fiber"
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader"
import { getMouseDegrees } from "./utils"

function moveJoint(mouse, joint, degreeLimit = 40) {
  let degrees = getMouseDegrees(mouse.current.x, mouse.current.y, degreeLimit)
  joint.rotation.xD = THREE.MathUtils.lerp(joint.rotation.xD || 0, degrees.y, 0.1)
  joint.rotation.yD = THREE.MathUtils.lerp(joint.rotation.yD || 0, degrees.x, 0.1)
  joint.rotation.x = THREE.Math.degToRad(joint.rotation.xD)
  joint.rotation.y = THREE.Math.degToRad(joint.rotation.yD)
}

export default function Model({ mouse, ...props }) {
  const group = useRef()
  const { nodes, animations } = useLoader(GLTFLoader, "/yao.glb")
  const actions = useRef()
  const [mixer] = useState(() => new THREE.AnimationMixer())
  useFrame((state, delta) => mixer.update(delta))
  useEffect(() => {
    actions.current = { idle: mixer.clipAction(animations[0], group.current) }
    actions.current.idle.play()
    return () => animations.forEach((clip) => mixer.uncacheClip(clip))
  }, [])

  useFrame((state, delta) => {
    mixer.update(delta)
    moveJoint(mouse, nodes.mixamorigNeck)
    moveJoint(mouse, nodes.mixamorigSpine)
  })

  return (
    <group ref={group} {...props} dispose={null}>
      <group rotation={[0, 0, 0]} scale={[10,10,10]}>
        <primitive object={nodes["mixamorigHips"]} />
        <skinnedMesh receiveShadow castShadow geometry={nodes["yao"].children[0].geometry} skeleton={nodes["yao"].children[0].skeleton}>
          <meshStandardMaterial skinning {...nodes["yao"].children[0].material} color= {"#fcb57c"} metalness={0.3} roughness={0.8} />
        </skinnedMesh>
        <skinnedMesh receiveShadow castShadow geometry={nodes["yao"].children[1].geometry} skeleton={nodes["yao"].children[1].skeleton}>
          <meshStandardMaterial skinning  {...nodes["yao"].children[1].material} roughness={0.8}shininess = {0}/>
        </skinnedMesh>
        <skinnedMesh receiveShadow castShadow geometry={nodes["yao"].children[2].geometry} skeleton={nodes["yao"].children[2].skeleton}>
          <meshStandardMaterial  skinning  {...nodes["yao"].children[2].material} roughness={0.8} shininess = {0}/>
        </skinnedMesh>
      </group>
    </group>
  )
}
