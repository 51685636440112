
import { Canvas } from "react-three-fiber"
import { getMousePos,getTouchPos } from "./utils"
import React, { Suspense, useRef,useState,useFrame } from "react"
import Yao from "./Yao"
import { OrbitControls,Text,softShadows, } from '@react-three/drei'
softShadows()
function Info() {
  return (
       <>
    <Text color ={"#444"}position={[-5,6,0]}fontSize={0.5}>Follow the </Text>
       
    <Text 
    position={[-3,6,0.1]}
    fontSize={0.5} 
    color={"#444"}
    outlineColor= {"orange"}
    outlineWidth={"2%"}
    outlineOffsetX={"10%"}
    outlineOffsetY={"10%"}
    onPointerDown={()=>window.open("https://tympanus.net/codrops/2019/10/14/how-to-create-an-interactive-3d-character-with-three-js/")} >    
    article </Text>
    <Text 
    color ={"#444"}
    position={[-1.8,6,0]}
    fontSize={0.5}> and </Text>

    <Text 
    position={[-0.5,6,0.1]}
    fontSize={0.5}
    color ={"#444"}
    outlineColor= {"orange"}
    outlineWidth={"2%"}
    outlineOffsetX={"10%"}
    outlineOffsetY={"10%"}
    onPointerDown={()=>window.open("https://codesandbox.io/embed/r3f-bones-3i7iu")} >    
     demo  </Text>
    <Text 
    position={[3.4,6,0]}
    color ={"#444"}
    fontSize={0.5}> plus some twists of my own.</Text>

    <Text 
    position={[0,5,-0.5]}
    color ={"#333"}
    maxWidth= {"15"}
    textAlign={"center"}
    fontSize={0.4}>3D Model scanned with Structure Sensor, cleaned with Rhino, 
    rigged with Mixamo, weight mapped with Blender. </Text>
    </>


  )
}

function Spin(){
    const [word,setWord] =useState("Loading");
    let count = 0;

    return (   
      <group>
        <Text fontSize={0.5}>{word}</Text>
       </group> 
      );
  }

export default function App() {
  const d = 8.25
  const mouse = useRef({ x: 0, y: 0 })
  return (
    <Canvas  style={{ background: "white" } } onMouseMove={(e) => (mouse.current = getMousePos(e))} onTouchStart={(e) => (mouse.current = getTouchPos(e))} shadowMap pixelRatio={[1, 1.5]} camera={{ position: [0, -3, 18] }}>
      <hemisphereLight skyColor={"black"} groundColor={0xffffff} intensity={1} position={[0, 10, 0]} />
      <directionalLight
        position={[-8, 15, 8]}
        shadow-camera-left={d * -1}
        shadow-camera-bottom={d * -1}
        shadow-camera-right={d}
        shadow-camera-top={d}
        shadow-camera-near={0.1}
        shadow-camera-far={150}
        castShadow
      />
      <spotLight
        intensity={0.2}
        angle={Math.PI / 3}
        color="#fff7d4"
        position={[-3, 5, 5]}
        penumbra={1}

      />
       <fog attach="fog" args={["#F8B195", 0, 100]} />

       <Info scale={[0.8,0.8,0.8]}/>


      {/* <Plane rotation={[-0.5 * Math.PI, 0, 0]} position={[0, -10, 0]} /> */}
      <Suspense fallback={<Spin />}>
        <Yao mouse={mouse} position={[0, -10, -3]} scale={[0.08, 0.08, 0.08]} />
      </Suspense>

      <mesh position={[0, 0, -12]} >
        <circleBufferGeometry args={[8,64]} />
        <meshStandardMaterial color="orange" />
      </mesh>

      <mesh rotation={[-Math.PI / 2, 0, 0]} position={[0, -10.1, -10]} scale={[10,10,10]} receiveShadow>
        <planeBufferGeometry attach="geometry" args={[100, 100]}   />
        <shadowMaterial aattach="material" transparent opacity={0.4} /> 
      </mesh>

      <OrbitControls 

          enablePan={false}
          enableZoom={true}
          enableDamping
          dampingFactor={ 0.05}
          rotateSpeed={2}
          maxDistance= {20}
          minDistance= {15}
          maxPolarAngle={Math.PI / 1.7}
          minPolarAngle={Math.PI / 2.4}
          maxAzimuthAngle ={Math.PI / 20}
          minAzimuthAngle ={-Math.PI / 20}/>
        <group rotation={[0,0,0]}position={[0,0,-5003]} scale={[1,1,500]}>
        <gridHelper position={[0,-10,0]} rotation={[0,0,0]} args={[20,100, "gray","gray"]}  />
        <gridHelper position={[0,10,0]} rotation={[0,0,0]} args={[20,100, "gray","gray"]} />
        <gridHelper position={[-10,0,0]} rotation={[0,0,Math.PI/2]} args={[20,100, "gray","gray"]} />
        <gridHelper position={[10,0,0]} rotation={[0,0,Math.PI/2]} args={[20,100, "gray","gray"]} />
        <gridHelper position={[0,0,-1]} rotation={[Math.PI/2,0,0]} args={[20,100, "gray","gray"]} />

        </group>
    </Canvas>


  )
}
