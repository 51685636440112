import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Figure from '../components/Figure/Figure'

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`;

  return (
    <Layout location={location} title={siteTitle} fullScreen ={true}>
      <Figure/>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }

  }
`
